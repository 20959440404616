import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, tablePaginationClasses, TextField } from '@mui/material';
import Slider from 'react-slick';
import '../mainHomePage/MainHomePage.css';

import home_banner from '../../images/home_banner.png';
import benner__pen__icon from '../../images/benner__pen__icon.png';
import trading__img from '../../images/trading__img.png';
import export__img from '../../images/export__img.png';
import import__img from '../../images/import__img.png';
import brand__img from '../../images/brand__img.png';
import business__coverage__img from '../../images/business__coverage__img.png';
import tshart1 from '../../images/tshart1.png';
import tshart2 from '../../images/tshart2.png';
import tshart3 from '../../images/tshart3.png';
import tshart4 from '../../images/tshart4.png';
import cap_img from '../../images/cap_img.png';
import tupi_img from '../../images/tupi_img.png';
import shoo_img from '../../images/shoo_img.png';
import location_img from '../../images/location_img.png';
import call_img from '../../images/call_img.png';
import email_img from '../../images/email_img.png';
import elegantInternationMap from '../../images/elegantInternationMap.png';
import deal from '../../images/deal.png';
import business from '../../images/business.png';
import export_img from '../../images/export_img.png';
import business__cover_img from '../../images/business__cover_img.png';
import Hassle_free from '../../images/Hassle_free.jfif';
import Expert_guidance from '../../images/Expert_guidance.jfif';
import Enhance from '../../images/Enhance.jfif';
import sendmessage from '../../images/sendmessage.png';
import Reliable_service from '../../images/Reliable_service.png';
import SendMessage from './SendMessage';

const MainHomePage = () => {
    const [servicesData, setServicesData] = useState([
        { id: 1, photo: export_img, title_name: "Export Import", desc: "We have a team of experienced professionals who are knowledgeable in international trade, and they work closely with clients to help them navigate the complexities of importing and exporting goods and services." },
        { id: 2, photo: business, title_name: "Access to global markets.", desc: "Elegant International provides a gateway for businesses to expand their reach in the global market. We ensure hassle-free import-export of goods that meet international standards." },
        { id: 3, photo: deal, title_name: "Trading solutions.", desc: "We prioritize reliability and quality of service. Our team of experts ensures seamless transactions and timely delivery of goods with transparency and accountability." },
        { id: 3, photo: Reliable_service, title_name: "Reliable service.", desc: "We prioritize reliability and quality of service. Our team of experts ensures seamless transactions and timely delivery of goods with transparency and accountability." },
    ]);

    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const settings = {
        dots: true,
        centerMode: true,
        infinite: true,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1
    };

    const [tabList, settabList] = useState([
        { id: 1, name: "t-shirt" },
        { id: 2, name: "jacket" },
        { id: 3, name: "fashion" },
        { id: 4, name: "jewelry" },
        { id: 5, name: "cap & hat" },
        { id: 6, name: "Dry Food" },
        { id: 7, name: "candle" },
        { id: 8, name: "Home decor" },
        { id: 9, name: "All Bag" },
    ]);

    const carusolImageSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 3
    };
    const [carusolImage, setCarusolImage] = useState([
        { id: 1, photo: tshart1, },
        { id: 2, photo: tshart2, },
        { id: 3, photo: tshart3, },
        { id: 4, photo: tshart4, },
        { id: 5, photo: shoo_img, },
        { id: 6, photo: tupi_img, },
        { id: 7, photo: cap_img, },
    ])
    return (
        <>
            {/* <nav className="navbar navbar-expand-lg navbar-light bg-white"id="navbar-eaxmple-ZTL">
                <div className="container container-fluid ">
                    <a href="#home" className="navbar-brand" >
                        <img src={logo} className=" bg-white img-fluid" style={{ width: '145px', height: '35px' }} alt="logo" border="0" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">

                        <ul className="navbar-nav " >
                            <li className="nav-item px-2">
                                <a href='#home' className="nav-link active" aria-current="page">Home</a>
                            </li>
                            <li className="nav-item px-2">
                                <a href='#about-us' className="nav-link">About Us</a>
                            </li>
                            <li className="nav-item px-2">
                                <a href='#features' className="nav-link" >Features</a>
                            </li>
                            <li className="nav-item px-2">
                                <a href='#try-demo' className="nav-link" >Try Demo</a>
                            </li>
                            <li className="nav-item px-2">
                                <a href='#contact' className="nav-link" >Contact</a>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav> */}

            <div data-bs-spy="scroll" data-bs-target="#navbar-eaxmple-ZTL" data-bs-offset="0" className="scrollspy-example" tabIndex="0">

                <div id='home'>
                    <div className="home__banner">
                        <img src={home_banner} alt="Snow" />
                        <div className="home__banner__overlay">
                            <h2 className="txt__banner">Streamline Your Global Trade with Elegant International.</h2>
                            <h4 className="subtxt__banner pt-2">We handle the logistics so you can focus on growing your business globally.</h4>
                            <div className="banner__dec">
                                <p>
                                    Elegant International is Export Import business place and we are doing business with the foreign companies.
                                    Focus on promoting your merchandise on your goal and supporting you by providing them global export
                                    import shipping services. Elegant International, a premier exporter and importer of a wide range of
                                    industrial and consumer products. We specialize in serving the international trade markets. As an exporter
                                    and importer, we open doors for your business to flourish.
                                </p>
                                {/* <button className="btn__banner"><img src={benner__pen__icon} alt="" style={{ width: "20px", height: "22px" }} /> <span className="ms-2">Enquiry</span></button> */}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container mb-4 ">
                    <section>
                        <div className="row mt__top" id="about-us">
                            <h3 className="text-center ">About Us</h3>
                            <div className="col-md-12">
                                <p className="desc__about">
                                    Elegant International is an import-export business located in the heart of Dhaka. We specialize in
                                    exporting products to customers all over the world, with a focus on providing the best quality goods at
                                    competitive prices. Our mission is to create long-term partnerships with our customers, by delivering the
                                    highest level of service and professionalism. We strive to provide an efficient and reliable solution for
                                    our customers, meeting their individual needs and exceed their expectations. With our vast network of
                                    suppliers and vendors, we offer a wide range of products, ensuring that our customers get the best product
                                    for their needs.
                                </p>
                                <p className="desc__about">
                                    Also, Elegant International is a professional import/export company based in Dhaka, Bangladesh. We specialize in
                                    providing quality products to our customers all over the world. We employ a team of experienced professionals
                                    who have a passion for connecting customers with the products they need. Our mission is to provide our customers
                                    with the best possible service and quality products at competitive prices in an efficient manner. We strive to
                                    always offer a highly personal and professional service that puts our customers first. We are committed to
                                    delivering the highest level of customer satisfaction and continually improving our services.
                                </p>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="row mt__top " id="services">
                            <div className="col-md-12">
                                <h3 className="text-center ">Services</h3>
                            </div>
                        </div>
                        <div className="row g-3 pt-5 d-flex justify-content-center">
                            {
                                servicesData.length > 0 && servicesData.map((item, id) => {
                                    return (
                                        <div key={id} className="col-md-3">
                                            <div className="services__img">
                                                <img src={item.photo} alt="image" />
                                            </div>
                                            <h5 className="text-center pt-3">{item.title_name}</h5>
                                            <p className="desc__about">{item.desc}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </section>

                    <section>
                        <div className="row g-3 mt__services" id="services">
                            <div className="col-md-6 pe-md-5">
                                <div className="blog__img">
                                    <img src={Hassle_free} alt="image" loading='lazy' />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 className="mt__blog">Hassle-free shipping and logistics.</h3>
                                <p className="pt-2">Our comprehensive shipping and logistics services will help you navigate the complex world of global trade with ease. Whether you need air, land, or sea freight, we have you covered.</p>
                            </div>
                        </div>
                        <div className="row g-3 pt-5 flex-column-reverse flex-md-row">
                            <div className="col-md-6">
                                <h3 className="mt__blog">Expert guidance and support.</h3>
                                <p className="pt-2">From customs clearance to regulatory compliance, our experienced team provides personalized guidance and support every step of the way. We help you overcome any challenges in international trade.</p>
                            </div>
                            <div className="col-md-6 ps-md-5">
                                <div className="blog__img">
                                    <img src={Enhance} alt="image" loading='lazy' />
                                </div>
                            </div>
                        </div>
                        <div className="row g-3 pt-5">
                            <div className="col-md-6 pe-md-5">
                                <div className="blog__img">
                                    <img src={Expert_guidance} alt="image" loading='lazy' />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 className="mt__blog">Enhance your global reach.</h3>
                                <p className="pt-2">With our vast network of agents and partners around the world, we help you expand your business globally. We connect you with new markets, new customers, and new opportunities for growth.</p>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="row mt-5" id="products">
                            <div className="col-md-12">
                                {/* <div className="products__item">
                                <h3 className="text-center pt-5">Product & Items</h3>
                                <div className="mt-4">
                                    <div className="d-flex justify-content-center product__tab">
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="pills-import-tab" data-bs-toggle="pill" data-bs-target="#pills-import" type="button" role="tab" aria-controls="pills-import" aria-selected="true">Import</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-export-tab" data-bs-toggle="pill" data-bs-target="#pills-export" type="button" role="tab" aria-controls="pills-export" aria-selected="false">Export</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-trading-tab" data-bs-toggle="pill" data-bs-target="#pills-trading" type="button" role="tab" aria-controls="pills-trading" aria-selected="false">Trading</button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-import" role="tabpanel" aria-labelledby="pills-import-tab" tabIndex="0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="product__list__tab">
                                                        <div className="product__carousol__list">
                                                            <Slider {...settings}>
                                                                {
                                                                    tabList.length > 0 && tabList.map((item, id) => {
                                                                        return (
                                                                            <div key={id} className="carousol__tab">
                                                                                <span>{item.name}</span>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Slider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pt-3">
                                                <div className="col-12">
                                                    <div className="product__carousol">
                                                        <div className="row g-3 mx-3">
                                                            <Slider {...carusolImageSlider}>
                                                                {
                                                                    carusolImage.length > 0 && carusolImage.map((item, id) => {
                                                                        return (
                                                                            <div className="col-md-4">
                                                                                <div key={id} className="card">
                                                                                    <img src={item.photo} alt="" />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Slider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-export" role="tabpanel" aria-labelledby="pills-export-tab" tabIndex="0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="product__list__tab">
                                                        <div className="product__carousol__list">
                                                            <Slider {...settings}>
                                                                {
                                                                    tabList.length > 0 && tabList.map((item, id) => {
                                                                        return (
                                                                            <div key={id} className="carousol__tab">
                                                                                <span>{item.name}</span>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Slider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pt-3">
                                                <div className="col-12">
                                                    <div className="product__carousol">
                                                        <div className="row g-3 mx-3">
                                                            <Slider {...carusolImageSlider}>
                                                                {
                                                                    carusolImage.length > 0 && carusolImage.map((item, id) => {
                                                                        return (
                                                                            <div className="col-md-4">
                                                                                <div key={id} className="card">
                                                                                    <img src={item.photo} alt="" />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Slider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-trading" role="tabpanel" aria-labelledby="pills-contact-tab" tabIndex="0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="product__list__tab">
                                                        <div className="product__carousol__list">
                                                            <Slider {...settings}>
                                                                {
                                                                    tabList.length > 0 && tabList.map((item, id) => {
                                                                        return (
                                                                            <div key={id} className="carousol__tab">
                                                                                <span>{item.name}</span>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Slider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pt-3">
                                                <div className="col-12">
                                                    <div className="product__carousol">
                                                        <div className="row g-3 mx-3">
                                                            <Slider {...carusolImageSlider}>
                                                                {
                                                                    carusolImage.length > 0 && carusolImage.map((item, id) => {
                                                                        return (
                                                                            <div className="col-md-4">
                                                                                <div key={id} className="card">
                                                                                    <img src={item.photo} alt="" />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Slider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                                {/* <div className="brand mt-5">
                                <h4 className="text-center">Brand</h4>
                                <div className="brand__img mt-3">
                                    <img src={brand__img} alt="" />
                                </div>
                            </div> */}
                                <div className="mt__services">
                                    <div className="brand__img2">
                                        <img src={business__cover_img} alt="" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                    <section>
                        <div className="row g-3 mt-5" id="contact">
                            <div className="col-md-6 pe-md-5">
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1291.4452030176099!2d90.38878868592676!3d23.72031160803034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b94c5defd7db%3A0xfbe3840598158ab6!2sZaimah%20Technologies%20Limited!5e0!3m2!1sen!2snl!4v1673088897493!5m2!1sen!2snl" allowfullscreen="" style={{ width: "100%", height: "360px", border: "0px" }} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                <div className="elegant__img mt__top">
                                    <img src={elegantInternationMap} alt="map" loading='lazy' />
                                </div>
                            </div>
                            <div className="col-md-6 ps-md-5">
                                <h3 className="pt-5">Contact Us</h3>
                                <div className="contact__desc">
                                    <p>WE WOULD LOVE TO TALK TO YOU, FEEL FREE TO CONTACT WITH US FOR MORE DETAILS.
                                        YOU MAY HAVE OR ANY OF THE SERVICE THAT WE OFFER.</p>
                                </div>
                                <div className="d-flex pt-5 contact__p__img">
                                    <img src={location_img} alt="" className="me-3" />
                                    <div className="contact__p">
                                        <h6><b>Address</b></h6>
                                        <p>65 Begum Bazar, Dhaka-1211, Bangladesh</p>
                                    </div>
                                </div>
                                <div className="d-flex pt-3 contact__p__img">
                                    <img src={call_img} alt="" className="me-3" />
                                    <div className="contact__p">
                                        <h6><b>Call Us</b></h6>
                                        <p>+880 1819-973336, +880961729581</p>
                                    </div>
                                </div>
                                <div className="d-flex pt-3 contact__p__img">
                                    <img src={email_img} alt="" className="me-3" />
                                    <div className="contact__p" onClick={() => { window.location.href = 'mailto:info@elegent-international.com'; }}>
                                        <h6><b>Email</b></h6>
                                        <p>info@elegent-international.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row g-3 mt-5 flex-column-reverse flex-md-row">
                            <div className="col-md-7">
                                <SendMessage />
                            </div>
                            <div className="col-md-5">
                                <div className="elegant__img mt__top">
                                    <img src={sendmessage} alt="map" loading='lazy' />
                                </div>
                            </div>
                        </div>
                    </section>


                </div>

            </div>
        </>
    );
};

export default MainHomePage;