import React, { useState } from 'react';
import '../footer/Footer.css';

import fiata__img from '../../images/fiata__img.png';
import scb__img from '../../images/scb__img.png';
import fbcci__img from '../../images/fbcci__img.png';
import mcci__img from '../../images/mcci__img.png';
import e__img from '../../images/e__img.png';
import bgmea__img from '../../images/bgmea__img.png';
import eCab__img from '../../images/eCab__img.png';
import visa__img from '../../images/visa__img.png';
import bkash__img from '../../images/bkash__img.png';
import rocket__img from '../../images/rocket__img.png';
import nagad__img from '../../images/nagad__img.png';
import upay__img from '../../images/upay__img.png';
import surekash__img from '../../images/surekash__img.png';
import mycash__img from '../../images/mycash__img.png';
import icm_freight_img from '../../images/icm_freight_img.png';
import express_img from '../../images/express_img.png';
import bgba_img from '../../images/bgba_img.png';

const Footer = () => {
    const [accreditedMember, setAccreditedMember] = useState([
        { id: 1, photo: bgba_img },
        // { id: 1, photo: eCab__img },
        // { id: 2, photo: bgmea__img },
        // { id: 3, photo: e__img },
        // { id: 4, photo: mcci__img },
        // { id: 5, photo: fbcci__img },
        // { id: 6, photo: scb__img },
        // { id: 7, photo: fiata__img },
    ]);
    const [ourPartners, setOurPartners] = useState([
        // { id: 1, photo: eCab__img },
        // { id: 2, photo: bgmea__img },
        // { id: 3, photo: e__img },
        // { id: 4, photo: mcci__img },
        { id: 5, photo: express_img },
        { id: 6, photo: icm_freight_img },
    ]);
    const [weAccept, setWeAccept] = useState([
        { id: 1, photo: visa__img },
        { id: 2, photo: bkash__img },
        { id: 3, photo: rocket__img },
        { id: 4, photo: nagad__img },
        { id: 5, photo: upay__img },
        { id: 6, photo: surekash__img },
        { id: 7, photo: mycash__img },
    ]);


    return (
        <div className='footer_bg'>
            <div className="container pt-3">
                <div className="row g-3 gx-5 pt-3 d-flex justify-content-center">
                    <div className="col-md-5 pe-md-2">
                        <h5 className="ft__header">Contact Us</h5>
                        <p className="text__color mb-2 pt-2">Office Address</p>
                        <div className="d-flex mb-2">
                            <p> <i className="text__color fas fa-map-marker-alt"></i></p>
                            <p className="px-3 ft_description ">3 Dhakeswari Road, Lalbagh, Dhaka-1211, Bangladesh.</p>
                        </div>
                        <p className="text__color mb-3 pt-1">Corporate Office</p>
                        <div className="d-flex mb-2">
                            <p> <i className="text__color fas fa-map-marker-alt"></i></p>
                            <p className="px-3 ft_description ">65 Begum Bazar, Dhaka-1211, Bangladesh</p>
                        </div>
                        <div className="d-flex mb-2"  onClick={() => { window.location.href = 'mailto:info@elegent-international.com'; }}>
                            <p> <i className="text__color fas fa-envelope"></i></p>
                            <p className="px-3 ft_description ">info@elegent-international.com</p>
                        </div>
                        <div className="d-flex mb-2">
                            <p> <i className="text__color fab fa-whatsapp"></i></p>
                            <p className="px-3 ft_description ">+880 1819-973336</p>
                        </div>
                        <div className="d-flex mb-2">
                            <p> <i className="text__color fa-solid fa-phone"></i></p>
                            <p className="px-3 ft_description ">+880 1819-973336, +880961729581</p>
                        </div>
                        <div className="ht-social pt-3">
                            <a href="https://www.facebook.com/elegantinternationalbd" target="_blank" className="facebook"><i className="fab fa-facebook-f"></i></a>
                            {/* <a href="#" className="twitter"><i className="fab fa-twitter"></i></a> */}
                            <a href="https://www.linkedin.com/company/elegant-international-bd" className="linkedin" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                            <a href="+880 1819-973336" className="whatsapp" target="_blank"><i className="fab fa-whatsapp"></i></a>
                            {/* <a href="#" className="instagram"><i className="fab fa-instagram"></i></a> */}
                            {/* <a href="#" className="pinterest"><i className="fab fa-pinterest-p"></i></a> */}
                        </div>
                    </div>

                    <div className="col-md-5 ps-md-5">
                        <div className="mb-3">
                            <h5 className="ft__header">Accredited Member</h5>
                            <div className="">
                                {
                                    accreditedMember.length > 0 && accreditedMember.map((item, id) => <img key={id} src={item.photo} alt="image" className="me-3 mt-3" />)
                                }
                            </div>
                        </div>
                        <div className="mb-3">
                            <h5 className="ft__header">Our Partners</h5>
                            <div className="">
                                {
                                    ourPartners.length > 0 && ourPartners.map((item, id) => <img key={id} src={item.photo} alt="image" className="me-3 mt-3" />)
                                }
                            </div>
                        </div>
                        {/* <div className="mb-3">
                            <h5 className="ft__header">We Accept</h5>
                            <div className="">
                                {
                                    weAccept.length > 0 && weAccept.map((item, id) => <img key={id} src={item.photo} alt="image" className="me-3 mt-3" />)
                                }
                            </div>
                        </div> */}
                    </div>
                    <div className="footer__copyright pt-4 mb-4">
                        <div className="d-flex bd-highlight footer__copyright">
                            <div className="text-center p-2 flex-grow-1 bd-highlight">
                                <span >Copyright © 2015-2022 Elegant International All rights reserved.</span>
                            </div>
                            {/* <div className="p-2 ">
                                <a className="text-decoration-none text__color" href="/terms">Terms of Service </a>
                            </div>
                            <div className="p-2 bd-highlight">
                                <a className="text-decoration-none text__color" href="/privacy">Privacy</a>
                            </div>
                            <div className="p-2 bd-highlight">
                                <a className="text-decoration-none text__color" href="/security">Policy </a>
                            </div>
                            <div className="p-2 bd-highlight">
                                <a className="text-decoration-none text__color" href="/cookie-declaration">Support</a>
                            </div> */}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Footer;