import React from 'react';

const NotFound = () => {
    return (
        <div className="container vh-100">
            <h1 className="text-muted">Page Not Found 404 </h1>
        </div>
    );
};

export default NotFound;