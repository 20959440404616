//or 2nd way
import { useEffect } from 'react';              
import { Routes, Route, useLocation } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import "../node_modules/slick-carousel/slick/slick.css"; 
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "./App.css";
import Layouts from './components/layouts/Layouts';
import MainHomePage from './components/mainHomePage/MainHomePage';
import NotFound from './components/NotFound/NotFound';
import EnquiryNow from './components/enquiryNow/EnquiryNow';

function App() {
   
   //scroll top onClick in the new page
   const location = useLocation();
   useEffect(() => {
     window.scroll({
       top: 0,
       behavior: 'smooth'
     });
   }, [location]);

  return (
      <Routes>
        <Route path="/" element={<Layouts/>}>
          <Route index element={<MainHomePage/>}></Route>
          {/* <Route path="shop-now" element={<EnquiryNow/>}></Route> */}
          <Route path="*" element={<NotFound />} />
        </Route> 
      </Routes>
  );
}

export default App;
