import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./Header.css";
import logo from '../../images/logo.png';
import HeaderTop from './HeaderTop';


const Header = () => {
    const [navLink, setNavLink] = useState();

    const [modalGetFreeMarketingIsOpen, setModalGetFreeMarketingIsOpen] = useState(false);
    function openModalGetFreeMarketing(e) {
        e.preventDefault();
        // alert("mmm");
        setModalGetFreeMarketingIsOpen(true);
    }
    function closeModalGetFreeMarketing(e) {
        setModalGetFreeMarketingIsOpen(false);
    }

    const [contact, setContact] = useState({
        first_name: '',
        last_name: '',
        country: '',
        email: '',
        phone: '',
        website_link: '',
        company_name: '',
        company_size: '',
        comments: '',
        checkData: '',
    })
    const handleChange = (e) => {
        setContact({
            ...contact, [e.target.name]: e.target.value
        })

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log('state data checking', contact);
        // setContact({
        //     first_name: '',
        //     last_name: '',
        //     country: '',
        //     email: '',
        //     phone: '',
        //     website_link: '',
        //     company_name: '',
        //     company_size: '',
        //     comments: '',
        //     checkData: '',
        // });
        setModalGetFreeMarketingIsOpen(false);
        try {
            axios.post("send-mail", contact).then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    toast.success('Your Application request has been submitted Successfully.', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'toast-message'
                    });
                    setContact({
                        first_name: '',
                        last_name: '',
                        country: '',
                        email: '',
                        phone: '',
                        website_link: '',
                        company_name: '',
                        company_size: '',
                        comments: '',
                        checkData: '',
                    });
                } else {
                    toast.error('Your Application does not submit', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })

        } catch (error) {
            console.log(error);
        }

    };




    return (
        <div className="sticky__top">
            {/* <HeaderTop /> */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container container-fluid">
                    <a href="#home" className="navbar-brand" >
                        <img src={logo} className=" bg-white img-fluid" style={{ width: '145px', height: '35px' }} alt="logo" border="0" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo01">
                        <ul className="navbar-nav navbar__nav" >
                            <li onClick={() => setNavLink("home")} className="nav-item px-2">
                                <a href='#home' className={`${navLink === "home" && "shop__btn"} nav-link`} aria-current="page">Home</a>
                            </li>
                            <li onClick={() => setNavLink("about")} className="nav-item px-2">
                                <a href='#about-us' className={`${navLink === "about" && "shop__btn"} nav-link`}>About Us</a>
                            </li>
                            <li onClick={() => setNavLink("service")} className="nav-item px-2">
                                <a href='#services' className={`${navLink === "service" && "shop__btn"} nav-link`} >Services</a>
                            </li>
                            <li onClick={() => setNavLink("product")} className="nav-item px-2">
                                <a href='#products' className={`${navLink === "product" && "shop__btn"} nav-link`} >Products</a>
                            </li>
                            <li onClick={() => setNavLink("contact")} className="nav-item px-2">
                                <a href='#contact' className={`${navLink === "contact" && "shop__btn"} nav-link`} >Contact</a>
                            </li>
                            <li className="nav-item px-2" onClick={() => { setNavLink("#shop-now"); setModalGetFreeMarketingIsOpen(true) }}>
                                <a href='#shop-now' className={`${!navLink && "shop__btn"} nav-link shop__btn`} >Enquiry Now</a>
                            </li>
                            <div className="pt-0">

                                <ToastContainer
                                    position="top-center"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="light"
                                />
                                <Modal
                                    isOpen={modalGetFreeMarketingIsOpen}
                                    onRequestClose={closeModalGetFreeMarketing}
                                    // style={customStyles1}
                                    className="mymodal"
                                    overlayclassName="myoverlay"
                                    contentLabel="Example Modal"
                                >
                                    <div className='card-body modal__body'>
                                        <div className="pt-0">
                                            <span className='float-end' style={{ fontSize: "20px", cursor: "pointer" }} onClick={closeModalGetFreeMarketing}><i className="fa-solid fa-xmark close_btn"></i></span>
                                            <h6 className="header__modal__text">
                                                <div className="navbar-brand">
                                                    <img src={logo} alt="" width="100" height="30" />
                                                </div>
                                            </h6>
                                        </div>
                                        <div className="pt-2">
                                            <h5>Please enter your details:</h5>
                                            <p className="modal__p">Fill out this form and we will call you to understand your business and your goals. If we can help you, we will develop a free customised marketing plan.</p>
                                        </div>
                                        <form className="row g-3 pt-2 mb-3" onSubmit={handleSubmit}>
                                            <div className="col-md-6">
                                                <input type="text" className="form-control form-control-sm" placeholder="Your First Name" aria-label="First Name" name="first_name" value={contact.first_name} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="text" className="form-control form-control-sm" placeholder="Your Last Name" aria-label="Last Name" name="last_name" value={contact.last_name} onChange={handleChange} />
                                            </div>

                                            <div className="col-md-6">
                                                <input type="text" className="form-control form-control-sm" placeholder="Country" aria-label="Country" name="country" value={contact.country} onChange={handleChange} required />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="email" className="form-control form-control-sm" placeholder="Email Address" aria-label="Email address" name="email" value={contact.email} onChange={handleChange} required />
                                            </div>

                                            <div className="col-md-6">
                                                <input type="text" className="form-control form-control-sm" placeholder="Phone No" aria-label="Phone No" name="phone" value={contact.phone} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="text" className="form-control form-control-sm" placeholder="Website Link" aria-label="Website Link" name="website_link" value={contact.website_link} onChange={handleChange} />
                                            </div>

                                            <div className="col-md-6">
                                                <input type="text" className="form-control form-control-sm" placeholder="Company Name" aria-label="Company Name" name="company_name" value={contact.company_name} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6">
                                                <select className="form-select form-select-sm " placeholder="Company Size"
                                                    name="company_size"
                                                    onChange={handleChange}
                                                >
                                                    <option selected >Select your company size</option>
                                                    <option value="0-10">1-10</option>
                                                    <option value="11-20">10-20</option>
                                                    <option value="21-30">20-30</option>
                                                    <option value="10-50">10-50</option>
                                                    <option value="50-More">50-More</option>
                                                </select>
                                            </div>
                                            <div className="col-md-12">
                                                <textarea className="form-control form-control-sm" rows="2" placeholder="Write your comments here ..."
                                                    name="comments" value={contact.comments} onChange={handleChange}
                                                ></textarea>
                                            </div>


                                            <div className="col-md-12">
                                                <div className="form-check">
                                                    <input className="form-check-input" id="gridCheck"
                                                        type="checkbox"
                                                        name="checkData"
                                                        checked={contact.checkData ? true : false}
                                                        value={contact.checkData ? false : true}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="gridCheck">
                                                        <span className="text-muted">I agree to the</span>
                                                        <span className="text_color"> Privacy Policy</span>
                                                        <span className="text-muted"> and</span>
                                                        <span className="text_color"> Terms of Service.</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="text-start pt-2 mr-0">
                                                    <button type="submit" className="modal__btn"
                                                    // onClick={openModalGetFreeMarketing}
                                                    // onClick={closeModalGetFreeMarketing}
                                                    >
                                                        <b>Submit</b>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Modal>
                            </div>
                        </ul>

                    </div>
                </div>
            </nav>
            {/* <nav className="navbar navbar-expand-lg navbar-light bg-white" id="navbar-eaxmple-ZTL">
                <div className="container container-fluid ">
                    <div className="">
                        <a href="#home" className="navbar-brand" >
                            <img src={logo} className=" bg-white img-fluid" style={{ width: '145px', height: '35px' }} alt="logo" border="0" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">

                        <ul className="navbar-nav navbar__nav" >
                            <li onClick={() => setNavLink("home")} className="nav-item px-2">
                                <a href='#home' className={`${navLink === "home" && "shop__btn"} nav-link`} aria-current="page">Home</a>
                            </li>
                            <li onClick={() => setNavLink("about")} className="nav-item px-2">
                                <a href='#about-us' className={`${navLink === "about" && "shop__btn"} nav-link`}>About Us</a>
                            </li>
                            <li onClick={() => setNavLink("service")} className="nav-item px-2">
                                <a href='#services' className={`${navLink === "service" && "shop__btn"} nav-link`} >Services</a>
                            </li>
                            <li onClick={() => setNavLink("product")} className="nav-item px-2">
                                <a href='#products' className={`${navLink === "product" && "shop__btn"} nav-link`} >Products</a>
                            </li>
                            <li onClick={() => setNavLink("contact")} className="nav-item px-2">
                                <a href='#contact' className={`${navLink === "contact" && "shop__btn"} nav-link`} >Contact</a>
                            </li>
                            <li className="nav-item px-2" onClick={() => { setNavLink("#shop-now"); setModalGetFreeMarketingIsOpen(true) }}>
                                <a href='#shop-now' className={`${!navLink && "shop__btn"} nav-link shop__btn`} >Enquiry Now</a>
                            </li>
                        </ul>
                        
                    </div>

                    <div className="pt-0">

                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                        <Modal
                            isOpen={modalGetFreeMarketingIsOpen}
                            onRequestClose={closeModalGetFreeMarketing}
                            // style={customStyles1}
                            className="mymodal"
                            overlayclassName="myoverlay"
                            contentLabel="Example Modal"
                        >
                            <div className='card-body modal__body'>
                                <div className="pt-0">
                                    <span className='float-end' style={{ fontSize: "20px", cursor: "pointer" }} onClick={closeModalGetFreeMarketing}><i className="fa-solid fa-xmark close_btn"></i></span>
                                    <h6 className="header__modal__text">
                                        <div className="navbar-brand">
                                            <img src={logo} alt="" width="100" height="30" />
                                        </div>
                                    </h6>
                                </div>
                                <div className="pt-2">
                                    <h5>Please enter your details:</h5>
                                    <p className="ft__p">Fill out this form and we will call you to understand your business and your goals. If we can help you, we will develop a free customised marketing plan.</p>
                                </div>
                                <form className="row g-3 pt-2 mb-3" onSubmit={handleSubmit}>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control form-control-sm" placeholder="Your First Name" aria-label="First Name" name="first_name" value={contact.first_name} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control form-control-sm" placeholder="Your Last Name" aria-label="Last Name" name="last_name" value={contact.last_name} onChange={handleChange} />
                                    </div>

                                    <div className="col-md-6">
                                        <input type="text" className="form-control form-control-sm" placeholder="Country" aria-label="Country" name="country" value={contact.country} onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="email" className="form-control form-control-sm" placeholder="Email Address" aria-label="Email address" name="email" value={contact.email} onChange={handleChange} required />
                                    </div>

                                    <div className="col-md-6">
                                        <input type="text" className="form-control form-control-sm" placeholder="Phone No" aria-label="Phone No" name="phone" value={contact.phone} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control form-control-sm" placeholder="Website Link" aria-label="Website Link" name="website_link" value={contact.website_link} onChange={handleChange} />
                                    </div>

                                    <div className="col-md-6">
                                        <input type="text" className="form-control form-control-sm" placeholder="Company Name" aria-label="Company Name" name="company_name" value={contact.company_name} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6">
                                        <select className="form-select form-select-sm " placeholder="Company Size"
                                            name="company_size"
                                            onChange={handleChange}
                                        >
                                            <option selected >Select your company size</option>
                                            <option value="0-10">1-10</option>
                                            <option value="11-20">10-20</option>
                                            <option value="21-30">20-30</option>
                                            <option value="10-50">10-50</option>
                                            <option value="50-More">50-More</option>
                                        </select>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-check">
                                            <input className="form-check-input" id="gridCheck"
                                                type="checkbox"
                                                name="checkData"
                                                checked={contact.checkData ? true : false}
                                                value={contact.checkData ? false : true}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="gridCheck">
                                                <span className="text-muted">I agree to the</span>
                                                <span className="text_color"> Privacy Policy</span>
                                                <span className="text-muted"> and</span>
                                                <span className="text_color"> Terms of Service.</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="text-start pt-2 mr-0">
                                            <button type="submit" className="modal__btn"
                                            // onClick={openModalGetFreeMarketing}
                                            // onClick={closeModalGetFreeMarketing}
                                            >
                                                <b>Submit</b>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Modal>
                    </div>
                </div>
            </nav> */}
        </div>
    );
};

export default Header;