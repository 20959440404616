import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, tablePaginationClasses, TextField } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SendMessage = () => {
    const [contact, setContact] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        comments: '',
        checkData: '',
    })
    const handleChange = (e) => {
        setContact({
            ...contact, [e.target.name]: e.target.value
        })

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log('state data checking', contact);
        // setContact({
        //     first_name: '',
        //     last_name: '',
        //     email: '',
        //     phone: '',
        //     comments: '',
        //     checkData: '',
        // });

        try {
            axios.post("send-message", contact).then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    toast.success('Your Application request has been submitted Successfully.', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'toast-message'
                    });
                    setContact({
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        comments: '',
                        checkData: '',
                    });
                } else {
                    toast.error('Your Application does not submit', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })

        } catch (error) {
            console.log(error);
        }

    };


    return (
        <>
            <div className="send__message mb-3 d-flex justify-content-center">
                <form className="row g-3 pt-2 mb-3" onSubmit={handleSubmit} >
                    <h3 className="text-center mb-2">Send Your Message</h3>

                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    <div className="col-md-6">
                        <TextField
                            className="form__input"
                            id="outlined-password-input"
                            label="First Name"
                            type="text"
                            autoComplete="current-password"
                            name="first_name" value={contact.first_name} onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            className="form__input"
                            id="outlined-password-input"
                            label="Last Name"
                            type="text"
                            autoComplete="current-password"
                            name="last_name" value={contact.last_name} onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            className="form__input"
                            id="outlined-password-input"
                            label="Email Address "
                            type="email"
                            autoComplete="current-password"
                            name="email" value={contact.email} onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            className="form__input"
                            id="outlined-password-input"
                            label="Phone Number"
                            type="text"
                            autoComplete="current-password"
                            name="phone" value={contact.phone} onChange={handleChange}
                        />
                    </div>

                    <div className="col-md-12">
                        <div className="pt-3">
                            <TextField
                                className="form__input"
                                id="outlined-multiline-static"
                                label="Type Your Message"
                                variant="outlined"
                                multiline
                                rows={3}
                                name="comments" value={contact.comments} onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-check">
                            <input className="form-check-input" id="gridCheck"
                                type="checkbox"
                                name="checkData"
                                checked={contact.checkData ? true : false}
                                value={contact.checkData ? false : true}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="gridCheck">
                                <span className="text-muted">I agree to the</span>
                                <span className="text_color"> Privacy Policy</span>
                                <span className="text-muted"> and</span>
                                <span className="text_color"> Terms of Service.</span>
                            </label>
                        </div>
                    </div>


                    <div className="col-md-12">
                        <div className="text-center mt-3">
                            <button type="submit" className="submit__btn">Submit Now</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default SendMessage;